'use strict';

angular.module('enervexSalesappApp').controller('JobEditCtrl', function($scope, $stateParams, JobService, Job, Account, User, JobStatus, Contact, Company, AccountLocation, LocationData, Auth, Country, _, $state, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.jobStatuses = JobStatus.query({
		active:true
	});
	getJob();
	JobService.getStates().$promise.then(function(states){
		$scope.states = states;
	});
	Country.query({
		limit:300
	}).$promise.then(function(countries){
		$scope.countries = countries;
	});
	function getJob(){
		JobService.getJob().$promise.then(function(job){
			$scope.job = job;
			$scope.job.address.state =  job.address.state && job.address.state._id
		});
	}
	JobService.getAccountLocations().$promise.then(function(accountLocations){
		$scope.accountLocations = accountLocations
	});
	JobService.getContacts().$promise.then(function(res){
		$scope.contacts = res;
	});
	JobService.getAccount().$promise.then(function(res){
		$scope.account = res;
	});
	JobService.getCompanies().$promise.then(function(companies){
		$scope.companies = companies;
	});
	$scope.saveJob = function(){
		var payload = _.clone($scope.job)
		payload.client = $scope.job.client ? $scope.job.client._id : null
		JobService.updateJob(payload).$promise.then(function(res){
			$state.go('job', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
			}, {
				reload: true,
				inherit: false
			});
		});
	}
	$scope.deleteJob = function(job){
		JobService.deleteJob(job).$promise.then(function(res){
			$state.go('jobs', {
				accountId: $scope.accountId,
			}, {
				reload: true,
				inherit: false
			});
		});
	}
	$scope.checkEnvironment = function() {
		LocationData.query({
			zip: $scope.job.address.zip,
			state: $scope.job.address.state,
			city: $scope.job.address.city
		}).$promise.then(function(datas){
			var data = (datas && datas.length >0) ? datas[0] : null;
			if (data) {
				$scope.job.environment = data.environment;
				$scope.job.locationData = data;
				$scope.job.address.state = data.state && data.state._id
				$scope.job.address.city = data.city;
				$scope.job.address.zip = data.zip_code;
				$scope.job.address.country = countryForLocationDataCode(data.country)
			}
		})
	}
	$scope.clientCompanyChanged = function() {
		if ($scope.job.client.locationData){
			$scope.job.environment = $scope.job.client.locationData.environment;
		} else {
			$scope.job.environment = {}
		}
		$scope.job.address = $scope.job.client.address;
	};
});
